import React, { Component } from 'react'
import { Route, Router } from 'react-router-dom'

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { SnackbarProvider } from 'notistack'
import WebSocketContext from './WebSocketContext'

import AppFrame from './components/organisms/AppFrame'
import PopupWrapper from './shared/components/popup/PopupWrapper'
import { createAsyncComponent, createPageWrapper } from '~shared/components/pages'
import PortalTheme, { THEME } from './shared/assets/styles/theme'
import { createBrowserHistory } from 'history'
import { ApiProvider } from '~shared/api/ApiContext'
import SnackMessages from './shared/components/molecules/SnackMessages'
import Chatbot from './shared/components/molecules/Chatbot'
import ErrorHandler from './shared/components/pages/ErrorHandler'

import ApiConnector from './shared/api/ApiConnector'
import PortalApi from './api/PortalApi'

import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName'
import jssPreset from '@material-ui/core/styles/jssPreset'
import { create } from 'jss'
import rtl from 'jss-rtl'
import JssProvider from 'react-jss/lib/JssProvider'
import io from 'socket.io-client'
import AnalyticsEvents from './shared/utils/AnalyticsEvents'
import IntlStyleFixes from './IntlStyleFixes'
import { WidthProvider } from '~src/new/app/providers/WidthProvider'
import {
  StoreProvider,
  withStoreContext,
} from '~src/new/app/providers/StoreProvider/ui/StoreProvider'
import { HistoryStoreProvider } from './new/app/providers/HistoryProvider'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const generateClassName = createGenerateClassName()
const apiUrl = process.env.REACT_APP_API_URL
const apiConnector = new ApiConnector(apiUrl)
const portalApi = new PortalApi(apiConnector)

if (process.env.NODE_ENV !== 'production') {
  console.info('type "logout()" in the console to logout')
  window.logout = () => portalApi.logout()
}

const history = createBrowserHistory()

const componentByName = {
  Dashboard: createAsyncComponent(() => import('./new/pages/Dashboard')),
  SearchMobilePopupTemplate: createAsyncComponent(() =>
    import('./components/molecules/SearchMobile'),
  ),

  LoginPageEmailaddress: createAsyncComponent(() =>
    import('./components/pages/LoginPage/LoginPage'),
  ),
  LoginPageMobilephone: createAsyncComponent(() =>
    import('./components/pages/LoginPage/LoginPage'),
  ),
  EmptyPageMindBox: createAsyncComponent(() => import('./components/pages/EmptyPageMindBox')),

  MaintenancePage: createAsyncComponent(() => import('./components/pages/MaintenancePage')),

  LoginProblemsPageEmailaddress: createAsyncComponent(() =>
    import('./shared/components/pages/ResetPasswordPage'),
  ),
  LoginProblemsPageMobilephone: createAsyncComponent(() =>
    import('./components/pages/LoginProblemsPageMobilephone'),
  ),

  LoginProblemsSetPassword: createAsyncComponent(() =>
    import('./components/pages/LoginSetPasswordPage'),
  ),
  RegistrationSuccessPage: createAsyncComponent(() =>
    import('./components/pages/RegistrationSuccessPage'),
  ),
  RegistrationByInvitePage: createAsyncComponent(() =>
    import('./components/pages/RegistrationByInvitePage'),
  ),
  SignupPageMobilephone: createAsyncComponent(() =>
    import('./components/pages/SignupPageMobilephone'),
  ),
  MiscPageTemplate: createAsyncComponent(() =>
    import('./components/templates/ModularPageTemplate'),
  ),
  MiscOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/MiscOverviewTemplate'),
  ),
  WorldOfTobaccoDetailTemplate: createAsyncComponent(() =>
    import('./components/templates/WorldOfTobaccoDetailTemplate'),
  ),
  BrandOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/BrandOverviewTemplate'),
  ),
  BrandDetailTemplate: createAsyncComponent(() =>
    import('./components/templates/BrandDetailTemplate'),
  ),
  NewsOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/NewsOverviewTemplate'),
  ),
  ProfilePageTemplate: createAsyncComponent(() => import('./new/pages/ProfilePageTemplate')),
  ContactPageTemplate: createAsyncComponent(() =>
    import('./components/templates/ContactPageTemplate'),
  ),
  ProductOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/ProductOverviewTemplate'),
  ),
  ProductDetailTemplate: createAsyncComponent(() =>
    import('./components/templates/ProductDetailTemplate'),
  ),
  RewardOverviewTemplate: createAsyncComponent(() => import('./new/pages/RewardOverviewTemplate')),
  RewardDetailTemplate: createAsyncComponent(() => import('./new/pages/RewardDetailTemplate')),
  TaskFourDetailTemplate: createAsyncComponent(() => import('./new/pages/TaskFourDetailTemplate')),
  MyLoyaltyOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/MyLoyaltyOverviewTemplate'),
  ),
  SalesIndexOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/SalesIndexOverviewTemplate'),
  ),
  ExpandablesOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/ExpandablesOverviewTemplate'),
  ),
  ConsumerProgramsOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/ConsumerProgramsOverviewTemplate'),
  ),
  ConsumerTrialsOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/ConsumerTrialsOverviewTemplate'),
  ),
  TrialsHistoryPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/TrialsHistoryPopupTemplate'),
  ),
  ConsumerProgramDetailTemplate: createAsyncComponent(() =>
    import('./components/templates/ModularPageTemplate'),
  ),
  OfflineProgramsOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/OfflineProgramsOverviewTemplate'),
  ),
  OfflineProgramDetailsTemplate: createAsyncComponent(() =>
    import('./components/templates/OfflineProgramDetailsTemplate'),
  ),
  PackTrackPageTemplate: createAsyncComponent(() =>
    import('./components/templates/PackTrackPageTemplate'),
  ),
  PlanogramOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/PlanogramOverviewTemplate'),
  ),
  SpecialOffersOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/SpecialOffersOverviewTemplate'),
  ),
  SpecialOfferDetailTemplate: createAsyncComponent(() =>
    import('./components/templates/SpecialOfferDetailTemplate'),
  ),
  EduActionOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/EduActionOverviewTemplate'),
  ),
  ActivitiesOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/ActivitiesOverviewTemplate'),
  ),
  GenericMessagePage: createAsyncComponent(() =>
    import('./shared/components/templates/GenericMessagePage'),
  ),

  OrdersTemplate: createAsyncComponent(() => import('./components/templates/OrdersTemplate')),
  ShoppingCartTemplate: createAsyncComponent(() =>
    import('./components/templates/ShoppingCartTemplate'),
  ),

  MessagePopupTemplate: createAsyncComponent(() =>
    import('./shared/components/templates/GenericMessagePopupTemplate'),
  ),
  MyBalancePopupTemplate: createAsyncComponent(() =>
    import('./components/templates/MyBalancePopupTemplate'),
  ),
  MyRewardPopupTemplate: createAsyncComponent(() =>
    import('./new/pages/popup/MyRewardPopupTemplate'),
  ),
  RewardOrderConfirmPopupTemplate: createAsyncComponent(() =>
    import('./new/pages/popup/RewardOrderConfirmPopupTemplate'),
  ),
  MyRankPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/MyRankPopupTemplate'),
  ),
  ContactPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/ContactPopupTemplate'),
  ),
  FeedbackPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/ContactPopupTemplate'),
  ),

  OrderHistoryPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/OrderHistoryPopupTemplate'),
  ),
  AgreementRewardsProgressPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/AgreementRewardsProgressPopupTemplate'),
  ),
  MyAgreementsRewardsProgressPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/MyAgreementsRewardsProgressPopupTemplate'),
  ),
  UserFilesPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/UserFilesPopupTemplate'),
  ),
  VerifyPopupTemplate: createAsyncComponent(() => import('./new/pages/popup/VerifyPopupTemplate')),
  CaptchaPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/CaptchaPopupTemplate'),
  ),
  QuizsPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/QuizzesPopupTemplate'),
  ),
  ChallengesPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/ChallengesPopupTemplate'),
  ),
  NotificationCenterPopupTemplate: createAsyncComponent(() =>
    import('./new/pages/popup/NotificationCenterPopupTemplate'),
  ),
  GamesPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/GamesPopupTemplate'),
  ),
  SurveysPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/SurveysPopupTemplate'),
  ),
  PinsPopupTemplate: createAsyncComponent(() => import('./components/templates/PinsPopupTemplate')),
  CmsPushNotificationsPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/NotificationsPopupTemplate'),
  ),
  RewardsHistoryPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/RewardsHistoryPopupTemplate'),
  ),
  ConsumerSurveysPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/ConsumerSurveysPopupTemplate'),
  ),
  ConsumerDataCollectionOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/ConsumerDataCollectionOverviewTemplate'),
  ),
  ConsumerDataHistoryPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/ConsumerDataHistoryPopupTemplate'),
  ),
  TrialsPopupTemplate: createAsyncComponent(() =>
    import('./components/templates/ConsumerTrialsPopupTemplate'),
  ),

  MyAgreementsOverviewTemplate: createAsyncComponent(() =>
    import('./components/templates/MyAgreementsOverviewTemplate'),
  ),

  MyAgreementsDetailTemplate: createAsyncComponent(() =>
    import('~components/templates/MyAgreementsDetailTemplate/MyAgreementsDetailTemplate'),
  ),

  /* Russia */
  CmsAgreementsPopupTemplate: createAsyncComponent(() =>
    import('./ru/components/AgreementsPopupTemplate'),
  ),
  CmsComplianceReportPopupTemplate: createAsyncComponent(() =>
    import('./ru/components/ComplianceReportPopupTemplate'),
  ),
  MyProgramsOverviewTemplate: createAsyncComponent(() =>
    import('./new/pages/MyProgramsOverviewTemplate'),
  ),
  MyProgramsOverviewTemplateRRP: createAsyncComponent(() =>
    import('./ru/components/MyProgramsOverviewTemplate'),
  ),
  MyProgramsOverviewTemplateReg: createAsyncComponent(() =>
    import('./new/pages/MyProgramsOverviewTemplate'),
  ),
  MyProgramsOverviewTemplateKB: createAsyncComponent(() =>
    import('./ru/components/MyProgramsOverviewTemplate'),
  ),
  MyProgramsDetailTemplate: createAsyncComponent(() =>
    import('./new/pages/MyProgramsDetailTemplate'),
  ),
  ConditionSigningPopupTemplate: createAsyncComponent(() =>
    import('./ru/components/ConditionSigningPopupTemplate'),
  ),
  AllConditionsSigningPopupTemplate: createAsyncComponent(() =>
    import('./ru/components/AllConditionsSigningPopupTemplate'),
  ),
  SaToApprovePopupTemplate: createAsyncComponent(() =>
    import('./ru/components/SaToApprovePopupTemplate'),
  ),
  NotificationPageOverviewTemlate: createAsyncComponent(() =>
    import('./ru/components/NotificationPageOverviewTemlate'),
  ),
  NotificationPageDetailTemplate: createAsyncComponent(() =>
    import('./ru/components/NotificationPageDetailTemplate'),
  ),

  /* Canary Island */
  IcProductDetailTemplate: createAsyncComponent(() =>
    import('./ic/components/templates/ProductDetailTemplate'),
  ),
  IcProductOverviewTemplate: createAsyncComponent(() =>
    import('./ic/components/templates/ProductOverviewTemplate'),
  ),
  IcSpecialOfferDetailTemplate: createAsyncComponent(() =>
    import('./ic/components/templates/SpecialOfferDetailTemplate'),
  ),

  /* Benelux */
  PrepaidCardPage: createAsyncComponent(() => import('./be/components/PrepaidCardPage')),
  CollaborationPage: createAsyncComponent(() => import('./be/components/CollaborationPage')),
  PartnerPlanOverviewPage: createAsyncComponent(() =>
    import('./be/components/PartnerPlanOverviewPage'),
  ),
  PartnerPlanDetailPage: createAsyncComponent(() =>
    import('./be/components/PartnerPlanDetailPage'),
  ),
  CmsDataUsagePopupTemplate: createAsyncComponent(() =>
    import('./be/components/DataUsagePopupTemplate'),
  ),

  /* Poland */
  PlMyProgramsOverviewTemplate: createAsyncComponent(() =>
    import('./pl/components/MyProgramsOverviewTemplate'),
  ),
  PlMyProgramsDetailTemplate: createAsyncComponent(() =>
    import('./pl/components/MyProgramsDetailTemplate'),
  ),

  /* Singapore */
  SgMyProgramsOverviewTemplate: createAsyncComponent(() =>
    import('./sg/components/MyProgramsOverviewTemplate'),
  ),
  SgMyProgramsDetailTemplate: createAsyncComponent(() =>
    import('./sg/components/MyProgramsDetailTemplate'),
  ),

  /* United States*/
  PaymentsIndexOverviewTemplate: createAsyncComponent(() =>
    import('./us/components/templates/PaymentsIndexOverviewTemplate'),
  ),
  ContractsIndexOverviewTemplate: createAsyncComponent(() =>
    import('./us/components/templates/ContractsIndexOverviewTemplate'),
  ),
  USProfilePageTemplate: createAsyncComponent(() =>
    import('./us/components/templates/ProfilePageTemplate'),
  ),

  /* Kazakhstan */
  KzMyProgramsOverviewTemplate: createAsyncComponent(() =>
    import('./kz/components/MyProgramsOverviewTemplate'),
  ),
  KzMyProgramsDetailTemplate: createAsyncComponent(() =>
    import('./kz/components/MyProgramsDetailTemplate'),
  ),
  KzAgreementsPopupTemplate: createAsyncComponent(() =>
    import('./kz/components/AgreementsPopupTemplate'),
  ),
  ContractSigningPopupTemplate: createAsyncComponent(() =>
    import('./kz/components/ContractSigningPopupTemplate'),
  ),
}

const getComponentByName = name => {
  return componentByName[name]
}

let PageWrapper = createPageWrapper({
  getComponentByName,
  AppFrame: withStoreContext(AppFrame),
  PopupWrapper,
})

const MemberContext = React.createContext(null)

const withMember = Component => props => {
  return (
    <MemberContext.Consumer>
      {member => <Component {...props} member={member} />}
    </MemberContext.Consumer>
  )
}

export default class App extends Component {
  pageVisitTimeout = undefined
  triggerRetentionTimeout = undefined
  socket = undefined
  isSocketConnected = false
  hasSocketJoined = false
  analyticsEventData = undefined

  constructor(props) {
    super(props)
    this.state = {
      theme: createMuiTheme(THEME),
    }
  }

  handleThemeChange = newTheme => {
    if (newTheme.direction) document.body.dir = newTheme.direction
    this.setState({ theme: createMuiTheme({ ...THEME, ...newTheme }) })
  }

  joinSocketChannel = member => {
    const { socketChannel } = member
    if (this.hasSocketJoined || !socketChannel) return
    this.hasSocketJoined = true

    const res = /^(https?:\/\/)?(.*?)\/(.+)$/gi.exec(apiUrl)
    this.socket = io(res[2] || '/', { path: `/${res[3]}/socket`, transports: ['websocket'] })

    this.socket.once('connect', () => {
      this.socket.emit('join', socketChannel)
    })

    this.socket.on('updateJPoints', points => {
      const {
        member: { jpoints },
      } = this.state

      if (points === jpoints) return

      this.setState({ member: { jpoints: points } })
    })

    this.socket.on('updateNumCartItems', numItems => {
      const {
        member: { numCartItems },
      } = this.state

      if (numCartItems === numItems) return

      this.setState({ member: { numCartItems: numItems } })
    })

    this.socket.on('updatePreview', ({ updatedId }) => {
      this.setState({ _pageProps: { _updatedId: updatedId } })
      portalApi.connector.onNavigateTo('$current')
    })

    this.socket.on('navigateTo', href => {
      portalApi.connector.onNavigateTo(href)
    })

    this.socket.on('disconnect', () => {
      this.isSocketConnected = false
      this.hasSocketJoined = false
    })
  }

  joinWebPushNotifications = async firebaseConfig => {
    if (this.hasTokenConnected) return
    this.hasTokenConnected = true
    const { firebase } = window
    // Initialize Firebase
    if (!firebase || !firebase.messaging.isSupported())
      return console.warn('Push notifications are currently not supported')
    firebase.initializeApp(firebaseConfig)

    const messaging = firebase.messaging()
    const permission = await Notification.requestPermission().catch(console.error)
    // "default" | "denied" | "granted"
    if (permission !== 'granted') {
      portalApi.registerToken(null)
      return console.log('Notification not granted')
    }

    const fbSwRegistration = await navigator.serviceWorker
      .register('/v1/serviceworker.js')
      .then(registration => {
        registration.update()
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing
          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              newWorker.postMessage('skipWaiting')
            }
          })
        })
        return registration
      })
      .catch(console.error)

    const { vapidKey } = firebaseConfig

    messaging.usePublicVapidKey(vapidKey)
    messaging.useServiceWorker(fbSwRegistration)

    const currentToken = await messaging.getToken().catch(console.error)

    let webPushToken
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      function arrayBufferToBase64(buffer) {
        let binary = ''
        let bytes = new Uint8Array(buffer)
        let len = bytes.byteLength
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary)
      }

      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then(function (registration) {
          console.log('registration', registration)
          return registration.pushManager.getSubscription().then(async function (subscription) {
            console.log('subscription', subscription)
            if (subscription) {
              webPushToken = {
                endpoint: subscription.endpoint,
                keys: {
                  auth: arrayBufferToBase64(subscription.getKey('auth')),
                  p256dh: arrayBufferToBase64(subscription.getKey('p256dh')),
                },
              }
              return portalApi.registerWebPushToken(webPushToken)
            }

            const newSubscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: vapidKey,
            })

            if (newSubscription) {
              webPushToken = {
                endpoint: newSubscription.endpoint,
                keys: {
                  auth: arrayBufferToBase64(newSubscription.getKey('auth')),
                  p256dh: arrayBufferToBase64(newSubscription.getKey('p256dh')),
                },
              }
              return portalApi.registerWebPushToken(webPushToken)
            }
          })
        })
      } else {
        console.error('Push-уведомления не поддерживаются в вашем браузере.')
      }
    }

    console.log('App.js -> joinWebPushNotifications -> ', currentToken)
    portalApi.registerToken(currentToken)
  }

  componentDidUpdate() {
    if (this.analyticsEventData) {
      const { member, data } = this.analyticsEventData
      AnalyticsEvents.dataPush(member, data)
      this.analyticsEventData = undefined
    }
  }

  handleRouteChange = (
    { _id: routeId, pageData, pageData: { language, appFrame } = {}, member },
    data,
  ) => {
    // storing event data, so it can be picked up
    // by the 'componentDidUpdate' life cylce method after the page has completely been rendered
    this.analyticsEventData = { member, data }

    clearTimeout(this.pageVisitTimeout)
    clearTimeout(this.triggerRetentionTimeout)

    if (member) {
      // Join websocket with existing member
      this.joinSocketChannel(member)

      if (pageData && routeId) {
        const { props: { elements, _id, _link: { trigger = {} } = {} } = {} } = pageData
        let triggerRetentionTime
        if (routeId === _id) {
          triggerRetentionTime = trigger.retentionTime
        } else if (elements) {
          const childRoute = elements.find(({ _id }) => _id === routeId)
          if (childRoute) {
            const { _link: { trigger = {} } = {} } = childRoute
            triggerRetentionTime = trigger.retentionTime
          }
        }

        const memberId = member._id.toString()

        if (triggerRetentionTime !== undefined) {
          this.triggerRetentionTimeout = setTimeout(
            () => this.socket.emit('pageRetention', { routeId, memberId }),
            triggerRetentionTime * 1000,
          )
        }

        this.pageVisitTimeout = setTimeout(
          () => this.socket.emit('pageVisit', { routeId, memberId }),
          2000, //currently hard-coded to 2 secs
        )
      }
    }

    const {
      settings: {
        technical: {
          captchaId,
          captchaVisibility,
          gtmId,
          gtmSettings,
          addTags,
          chatbotEnabled,
          chatbotPath,
          chatbotColor,
          chatbotInputPlaceholder,
          chatbotNickname,
          chatbotSubtitle,
          chatbotButtonLabel,
          chatbotToken,
          chatbotAvatar,
          firebaseClient,
          endpointId,
        } = {},
        language: { currency } = {},
      } = {},
    } = appFrame || {}

    const chatbot =
      member && chatbotEnabled
        ? {
            path: chatbotPath,
            color: chatbotColor,
            placeholder: chatbotInputPlaceholder,
            nickname: chatbotNickname,
            subtitle: chatbotSubtitle,
            buttonLabel: chatbotButtonLabel,
            token: chatbotToken,
            avatar: chatbotAvatar,
            member,
          }
        : null

    member && firebaseClient && this.joinWebPushNotifications(firebaseClient)
    // Add tag manager
    gtmId && AnalyticsEvents.init(gtmId, gtmSettings, currency, addTags)

    // Add mindbox tracker. Minbox setup on page /s
    // endpointId && AnalyticsEvents.initMindBoxTracker(endpointId)

    // Add captcha protection
    apiConnector.setupCaptcha(captchaId, captchaVisibility)

    const { member: prevMember, language: prevLanguage } = this.state
    if ((member && member !== prevMember) || language !== prevLanguage)
      this.setState({ member, chatbot, language })
  }

  render() {
    const { member, chatbot, theme, language } = this.state

    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <PortalTheme.Provider value={this.handleThemeChange}>
          <WidthProvider>
            <StoreProvider>
              <MuiThemeProvider theme={theme}>
                <ErrorHandler>
                  <WebSocketContext.Provider value={{ socket: this.socket }}>
                    <MemberContext.Provider value={member}>
                      <Router history={history}>
                        <React.Fragment>
                          <IntlStyleFixes language={language} />
                          <ApiProvider api={portalApi}>
                            <SnackbarProvider maxSnack={4} dense>
                              <SnackMessages />
                              {chatbot && <Chatbot {...chatbot} />}
                              <Route
                                render={props => (
                                  <HistoryStoreProvider location={props.location}>
                                    <PageWrapper
                                      {...props}
                                      member={member}
                                      onRouteChange={this.handleRouteChange}
                                    />
                                  </HistoryStoreProvider>
                                )}
                              />
                            </SnackbarProvider>
                          </ApiProvider>
                        </React.Fragment>
                      </Router>
                    </MemberContext.Provider>
                  </WebSocketContext.Provider>
                </ErrorHandler>
              </MuiThemeProvider>
            </StoreProvider>
          </WidthProvider>
        </PortalTheme.Provider>
      </JssProvider>
    )
  }
}

export { getComponentByName, withMember }
